import { Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { AnimatePresence } from 'framer-motion';
import { GrazProvider } from 'graz';

import { Node } from 'pages/Node';
import { NodeList } from 'pages/NodeList';
import { Start } from 'pages/Start';
import { nesaTestnet } from 'shared/config/networks/nesaTestnet';
import { Tooltip } from 'shared/ui/Tooltip';

import 'react-toastify/dist/ReactToastify.css'; // eslint-disable-line import/no-webpack-loader-syntax

import { Header } from './ui/Header';

import './App.css';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 1,
    },
  },
});

export function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <GrazProvider grazOptions={{ autoReconnect: false, chains: [nesaTestnet] }}>
        <Tooltip.Provider>
          <AnimatePresence>
            <Header />
            <Routes>
              <Route element={<Start />} path="/" />
              <Route element={<Node />} path="/nodes/:nodeId" />
              <Route element={<NodeList />} path="/:publicKey/list" />
            </Routes>
          </AnimatePresence>
        </Tooltip.Provider>
      </GrazProvider>
      <ToastContainer />
    </QueryClientProvider>
  );
}
