import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { AnimatePresence, motion } from 'framer-motion';

import { useNodesByPublicKeyInfiniteQuery } from 'shared/api/nodes/useNodesByPublicKeyInfiniteQuery';
import { useNodesStatByPublicKeyQuery } from 'shared/api/nodes/useNodesStatByPublicKeyQuery';
import { AnimateRoute } from 'shared/ui/AnimateRoute';
import { Button } from 'shared/ui/Button';
import { Icon } from 'shared/ui/Icon';
import { NodeStatus } from 'shared/ui/NodeStatus/NodeStatus';
import { Spinner } from 'shared/ui/Spinner';

export const NodeList = () => {
  const { publicKey } = useParams<{ publicKey: string }>();
  const navigate = useNavigate();
  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isPending } =
    useNodesByPublicKeyInfiniteQuery({ limit: 10, publicKey: publicKey || '' }, { enabled: !!publicKey });
  const { data: stat, isPending: isStatPending } = useNodesStatByPublicKeyQuery(
    { publicKey: publicKey || '' },
    { enabled: !!publicKey },
  );

  const totalCount = stat?.total_count;

  const nodeList = data?.pages.flat() || [];

  return (
    <AnimateRoute>
      <h1 className="mx-auto mb-6 flex max-w-6xl items-center break-all text-xl font-light">
        <div className="flex items-center gap-2">
          <span className="font-semibold">Public Key: </span>

          <CopyToClipboard
            onCopy={() => {
              toast.success('Public key is copied');
            }}
            text={publicKey || ''}
          >
            <span className="group flex cursor-pointer items-center gap-2 transition-colors hover:text-primary-900">
              {publicKey ? `${publicKey.slice(0, 12)}...` : ''}{' '}
              <Icon
                className="cursor-pointer text-corduroy-500 transition-colors group-hover:text-primary-900"
                name="copy"
              />
            </span>
          </CopyToClipboard>
        </div>
      </h1>

      <div className="relative">
        <AnimatePresence>
          {isPending ? (
            <motion.div
              animate={{ opacity: 1 }}
              className="absolute top-0 flex w-full items-center justify-center py-6"
              exit={{ opacity: 0 }}
              initial={{ opacity: 0 }}
              key="loader"
            >
              <Spinner className="size-6" />
            </motion.div>
          ) : (
            <motion.div
              animate={{ opacity: 1 }}
              className="mx-auto flex max-w-6xl flex-col gap-2"
              exit={{ opacity: 0 }}
              initial={{ opacity: 0 }}
              key="list"
              // transition={{ duration: 1 }}
            >
              <div className="flex items-center gap-5">
                <div className="flex gap-2">
                  Total nodes: {isStatPending ? <Spinner className="size-4" /> : totalCount}
                </div>
                <div className="flex gap-2">
                  Up nodes: {isStatPending ? <Spinner className="size-4" /> : stat?.up_count}
                </div>

                <div className="flex gap-2">
                  Down nodes: {isStatPending ? <Spinner className="size-4" /> : stat?.down_count}
                </div>
              </div>
              {nodeList.map((node) => {
                return (
                  <div
                    className="flex w-full cursor-pointer items-center justify-between rounded-xl bg-white p-4 drop-shadow-smAll transition-colors hover:bg-primary-100"
                    key={node._id}
                    onClick={() => {
                      navigate(`/nodes/${node.node_id}`);
                    }}
                  >
                    <div className="flex gap-8">
                      <div className="flex w-28 flex-col">
                        <div>Moniker:</div>

                        <div className="font-light">{node.moniker || '-'}</div>
                      </div>

                      <div className="flex flex-col">
                        <div>Node ID:</div>

                        <div className="font-light">{node.node_id || '-'}</div>
                      </div>
                    </div>
                    <NodeStatus status={node.status} />
                  </div>
                );
              })}
              <div className="mx-auto mt-5 flex items-center justify-center">
                {hasNextPage && (
                  <Button
                    isLoading={isFetchingNextPage}
                    onClick={() => {
                      fetchNextPage();
                    }}
                  >
                    Load more
                  </Button>
                )}
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </AnimateRoute>
  );
};
