import { useQuery } from '@tanstack/react-query';

import type { UseQueryOptions } from 'shared/types';

import { axiosInstance } from 'shared/config/axiosInstance';

import type { NodeListStatResponse } from './types';

import { nodesKeys } from './queryKeys';

export const useNodesStatByPublicKeyQuery = (
  { publicKey }: { publicKey: string },
  options: UseQueryOptions<NodeListStatResponse> = {},
) => {
  return useQuery({
    queryFn: async ({ signal }) => {
      const res = await axiosInstance.get<NodeListStatResponse>(`/nodes/${publicKey}/list/stat`, {
        signal,
      });

      return res.data;
    },
    queryKey: nodesKeys.nodesByPublicKeyStat({ publicKey }),
    ...(options || {}),
  });
};
