export const nodesKeys = {
  base: ['nodes'],
  item: (params: { limit: number; publicKey: string; skip?: number }) => [...nodesKeys.base, 'node', params],
  itemSequence: (params: { limit: number; publicKey: string; skip?: number }) => [
    ...nodesKeys.base,
    'node-sequence',
    params,
  ],
  lastHeartbeat: (publicKey: string) => [...nodesKeys.base, 'lastHeartbeat', publicKey],
  nodeInfo: (publicKey: string) => [...nodesKeys.base, 'node-info', publicKey],
  nodeStat: (publicKey: string) => [...nodesKeys.base, 'stat', publicKey],
  nodesByPublicKey: (params: { limit: number; publicKey: string; skip?: number }) => [
    ...nodesKeys.base,
    'node-list',
    'public-key',
    params,
  ],
  nodesByPublicKeySingle: (params: { limit: number; publicKey: string; skip?: number }) => [
    ...nodesKeys.base,
    'node-list',
    'single-req',
    'public-key',
    params,
  ],
  nodesByPublicKeyStat: (params: { publicKey: string }) => [
    ...nodesKeys.base,
    'node-list',
    'stat',
    'public-key',
    params,
  ],
};
