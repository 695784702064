import { useQuery } from '@tanstack/react-query';

import type { UseQueryOptions } from 'shared/types';

import { axiosInstance } from 'shared/config/axiosInstance';

import type { NodeLastHeartbeatResponse } from './types';

import { nodesKeys } from './queryKeys';

export const useNodeLastHeartbeat = (
  { nodeId }: { nodeId: string },
  options: UseQueryOptions<NodeLastHeartbeatResponse> = {},
) => {
  return useQuery({
    queryFn: async ({ signal }) => {
      const res = await axiosInstance.get<NodeLastHeartbeatResponse>(`/nodes/${nodeId}/last-heartbeat`, {
        signal,
      });

      return res.data;
    },
    queryKey: nodesKeys.lastHeartbeat(nodeId),
    refetchInterval: 5 * 60 * 1000, // 5 mins
    refetchOnWindowFocus: true,
    ...(options || {}),
  });
};
