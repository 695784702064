import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAccount, useDisconnect } from 'graz';

import { WalletProviderModal } from 'features/WalletProviderModal';
import { Button } from 'shared/ui/Button';

import logoSrc from '../../assets/images/logo-name-black.svg';

export const Header = () => {
  const navigate = useNavigate();
  const { data: account } = useAccount();
  const { disconnectAsync } = useDisconnect();

  const [isProviderOpen, setIsProviderOpen] = useState(false);

  return (
    <div className="flex h-14 items-center justify-between px-8">
      <img className="max-h-6 cursor-pointer" onClick={() => navigate('/')} src={logoSrc} />

      <div>
        {account ? (
          <div className="flex items-center gap-2 text-sm font-light text-corduroy-600">
            {`${account.bech32Address.slice(0, 10)}...`}{' '}
            <Button onClick={() => disconnectAsync()} variant="filled-light">
              Disconnect
            </Button>
          </div>
        ) : (
          <Button key="connect-btn" onClick={() => setIsProviderOpen(true)}>
            Connect Wallet
          </Button>
        )}
      </div>

      <WalletProviderModal isOpen={isProviderOpen} onOpenChange={setIsProviderOpen} />
    </div>
  );
};
