import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { AnimateRoute } from 'shared/ui/AnimateRoute';
import { Button } from 'shared/ui/Button';
import { Icon } from 'shared/ui/Icon';
import { Input } from 'shared/ui/Input';

const PUBLIC_KEY_REGEX = /^[0-9a-fA-F]{66}$/;
export const Start = () => {
  const navigate = useNavigate();
  const [value, setValue] = useState('');

  const handleSubmit = () => {
    const isPublicKey = PUBLIC_KEY_REGEX.test(value.trim());

    if (isPublicKey) {
      navigate(`/${value}/list`);

      return;
    }

    navigate(`/nodes/${value}`);
  };

  return (
    <AnimateRoute className="flex flex-col">
      <div className="m-auto flex w-full max-w-[600px] flex-col px-2">
        <h1 className="mb-8 text-center text-2xl font-light">View Your Nesa Node</h1>
        <Input
          className="w-full !pr-0"
          classNameInputWrapper="pr-0"
          endSlot={
            <Button className="-mr-px size-10 px-2 2xl:w-12" disabled={!value} onClick={handleSubmit}>
              <Icon className="size-4 -rotate-90" name="arrowDownLongSm" />
            </Button>
          }
          onChange={(e) => setValue(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleSubmit();
            }
          }}
          placeholder="Enter your Node ID (found by running bash cmd) or Public Key"
          value={value}
        />
      </div>
      <div className="m-auto w-full max-w-[600px] flex-col px-2 pt-14">
        <h1 className="text-left text-2xl font-light">Quick Start</h1>
        <div className="mb-4 h-px bg-black/30" />
        <img
          alt="Nesa Bootstrap Process"
          className="inline-block max-w-full"
          data-target="animated-image.originalImage"
          src="https://raw.githubusercontent.com/nesaorg/bootstrap/master/images/bootstrap.gif"
        />
        <h2 className="mb-2 mt-4 text-xl" id="prereqs-a">
          Prerequisites
        </h2>
        <ul className="ml-4 list-disc">
          <AccountLink
            detail=" (HuggingFace API Key), via a free HuggingFace account"
            link={'https://huggingface.co/docs/hub/security-tokens'}
            name="HuggingFace User Access Token"
          />
          <AccountLink
            detail=", which simplifies private key exporting"
            link={'https://www.leapwallet.io/'}
            name="Leap Wallet"
          />
          <SoftwareLink
            link={'https://docs.nesa.ai/nesa/run-a-nesa-node/prerequisites#software-requirements'}
            name="cURL"
          />
          <SoftwareLink
            link={'https://docs.nesa.ai/nesa/run-a-nesa-node/prerequisites#software-requirements'}
            name="Docker"
          />
          <SoftwareLink
            detail=", if using a CUDA-enabled GPU"
            link={'https://docs.nesa.ai/nesa/run-a-nesa-node/prerequisites#software-requirements'}
            name="CUDA Toolkit"
          />
        </ul>
        <h2 className="mb-2 mt-4 text-xl">Installation</h2>
        <ol className="ml-4 list-decimal">
          <li>
            <span className="font-bold">Execute</span> in Terminal/Command Prompt:
            <br />
            <span className="select-all break-words bg-primary-100 font-fira text-sm">
              bash &lt;(curl -s https://raw.githubusercontent.com/nesaorg/bootstrap/master/bootstrap.sh)
            </span>
          </li>
          <li>
            <span className="font-bold">Wizardy:</span> choose this option for quick set up
          </li>
          <li>
            <span className="font-bold">Moniker:</span> enter a name for your node
          </li>
          <li>
            <span className="font-bold">Email:</span> enter an email address to associate with your node
          </li>
          <li>
            <span className="font-bold">Referral Code:</span> enter a referral code for bonus points, if you
            have one
            <br />
            Refer friends by giving them your Nesa wallet address, which begins with the word{' '}
            <span className="font-fira">nesa</span>, and can be found in Leap Wallet after clicking{' '}
            <span className="italic">Connect Wallet</span> at the top of this page
          </li>
          <li>
            <span className="font-bold">Private Key:</span> enter the private key to be associated with your
            node
            <br />
            First, click the <span className="italic">Connect Wallet</span> at the top of this page, then{' '}
            <a
              className="underline hover:opacity-60 active:opacity-40"
              href="https://leapwallet.notion.site/Find-seed-phrase-private-key-75a8835b9c6448eaa4acc4184c3a9991"
              rel="noopener noreferrer"
              target="_blank"
            >
              export your private key from Leap Wallet
            </a>{' '}
            (a prerequisite)
          </li>
          <li>
            <span className="font-bold">HuggingFace API Key:</span> enter your HuggingFace API Key (a
            prerequisites)
          </li>
          <li>
            <span className="font-bold">Yes:</span> start node with the selected configuration
          </li>
        </ol>
      </div>
      <div className="m-auto w-full max-w-[600px] flex-col px-2 pt-14">
        <h1 className="text-left text-2xl font-light">FAQ</h1>
        <div className="mb-4 h-px bg-black/30" />
        <div>
          <a
            className="underline hover:opacity-60 active:opacity-40"
            href="https://docs.nesa.ai/nesa/run-a-nesa-node/faq"
            rel="noopener noreferrer"
            target="_blank"
          >
            Documentation
          </a>
        </div>
        <div>
          <a
            className="underline hover:opacity-60 active:opacity-40"
            href="https://discord.gg/nesa"
            rel="noopener noreferrer"
            target="_blank"
          >
            Discord
          </a>
        </div>
      </div>
    </AnimateRoute>
  );
};

function SoftwareLink({ detail, link, name }: { detail?: string; link: string; name: string }) {
  return (
    <li className="">
      Install{' '}
      <a
        className="underline hover:opacity-60 active:opacity-40"
        href={link}
        rel="noopener noreferrer"
        target="_blank"
      >
        {name}
      </a>
      {detail}
    </li>
  );
}

function AccountLink({ detail, link, name }: { detail?: string; link: string; name: string }) {
  return (
    <li className="">
      <a
        className="underline hover:opacity-60 active:opacity-40"
        href={link}
        rel="noopener noreferrer"
        target="_blank"
      >
        {name}
      </a>
      {detail}
    </li>
  );
}
