import { useQuery } from '@tanstack/react-query';

import type { UseQueryOptions } from 'shared/types';

import { axiosInstance } from 'shared/config/axiosInstance';

import type { NodeResponse } from './types';

import { nodesKeys } from './queryKeys';

export const useNodeQuery = ({ nodeId }: { nodeId: string }, options: UseQueryOptions<NodeResponse> = {}) => {
  return useQuery({
    queryFn: async ({ signal }) => {
      const res = await axiosInstance.get<NodeResponse>(`/nodes/${nodeId}/general`, {
        signal,
      });

      return res.data;
    },
    queryKey: nodesKeys.nodeInfo(nodeId),
    refetchOnWindowFocus: true,
    ...(options || {}),
  });
};
