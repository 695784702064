import { useParams } from 'react-router-dom';

import { AnimatePresence, motion } from 'framer-motion';
import { DateTime } from 'luxon';
import { twMerge } from 'tailwind-merge';

import { useNodeLastHeartbeat } from 'shared/api/nodes/useNodeLastHeartbeat';
import { useNodeQuery } from 'shared/api/nodes/useNodeQuery';
import { useNodeStatQuery } from 'shared/api/nodes/useNodeStatQuery';
import { AnimateRoute } from 'shared/ui/AnimateRoute';
import { Icon } from 'shared/ui/Icon';
import { NodeStatus } from 'shared/ui/NodeStatus/NodeStatus';
import { Spinner } from 'shared/ui/Spinner';
import { Tooltip } from 'shared/ui/Tooltip';

const sectionTitleStyles = 'min-w-56 text-sm font-light text-corduroy-500 ';

const STATS_TO_RENDER = ['cpu', 'ip', 'gpu', 'ram', 'vram'];

export const Node = () => {
  const { nodeId = '' } = useParams<{ nodeId: string }>();

  console.log('nodeId', nodeId);
  const {
    data: stat,
    isFetching: isFetchingStat,
    refetch: refetchNodeStat,
  } = useNodeStatQuery({ nodeId }, { enabled: !!nodeId });
  const {
    data: { node } = {},
    isPending,
    refetch: refetchNode,
  } = useNodeQuery({ nodeId }, { enabled: !!nodeId });
  const {
    data: { lastHeartbeat } = {},
    isFetching: isFetchingLastHeartbeat,
    refetch: refetchLastHeartbeat,
  } = useNodeLastHeartbeat({ nodeId }, { enabled: !!nodeId });

  const handleDataRefresh = () => {
    refetchNodeStat();
    refetchNode();
    refetchLastHeartbeat();
  };

  // const { data, isFetching, isPending } = useGetNodeResultQuery(
  //   { limit: 1, publicKey: publicKey!, skip: activeIdx },
  //   { enabled: !!publicKey },
  // );

  // const activeItem = data?.data[0];

  return (
    <AnimateRoute>
      <h1 className="mx-auto mb-6 flex max-w-6xl items-center break-all text-xl font-light">
        <div>
          <span className="font-semibold">Node ID: </span>
          {nodeId || ''}
        </div>

        <div
          className="ml-3 cursor-pointer text-corduroy-400 transition-all hover:text-primary-800"
          onClick={handleDataRefresh}
        >
          <svg
            className="size-5"
            fill="none"
            stroke="currentColor"
            strokeWidth="1.5"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </h1>

      {isPending && (
        <div className="flex items-center justify-center py-8">
          <Spinner className="size-6" />
        </div>
      )}

      {!node && !isPending && (
        <div className="flex items-center justify-center py-8 text-center font-light text-corduroy-600">
          No data found for given node id
          {/* Search node by peer id */}
        </div>
      )}

      <AnimatePresence>
        {node && (
          <>
            <motion.div
              animate={{ opacity: 1 }}
              className="mx-auto mb-4 flex max-w-6xl flex-col rounded-xl bg-white/80 py-4 pl-8 pr-4"
              initial={{ opacity: 0 }}
            >
              <h4 className="mb-4 text-xl">General Information</h4>

              <div className="flex flex-wrap gap-8">
                {/* <div className="flex flex-col gap-1">
                  <div className={sectionTitleStyles}>IP Address</div>
                  <div className="text-lg">{node?.ip ?? '--'}</div>
                </div> */}

                {node?.specs &&
                  Object.entries(node?.specs).map(([key, value]) => {
                    if (!STATS_TO_RENDER.includes(key)) return null;

                    if (Array.isArray(value) && typeof value[0] === 'string') {
                      console.log(value);
                      const labelValue =
                        value.length === 1 && (value[0]?.trim() === 'NA' || value[0]?.trim() === 'MB')
                          ? '--'
                          : value.length > 0
                            ? value.join(', ')
                            : '--';
                      return (
                        <div className="flex flex-col gap-1" key={key}>
                          <div className={sectionTitleStyles}>{key.toUpperCase()}</div>
                          <div className="text-lg">{labelValue}</div>
                        </div>
                      );
                    }

                    return (
                      <div className="flex flex-col gap-1" key={key}>
                        <div className={sectionTitleStyles}>{key.toUpperCase()}</div>
                        <div className="text-lg">
                          {typeof value === 'string' &&
                            (value.trim() === 'MB' || value.trim() === 'NA' ? '--' : (value ?? '--'))}
                          {(typeof value !== 'string' && value) ?? '--'}
                        </div>
                      </div>
                    );
                  })}

                <div className="flex flex-col gap-1">
                  <div className={sectionTitleStyles}>Status</div>
                  <div className="text-lg">{node?.status && <NodeStatus status={node?.status} />}</div>
                </div>
              </div>
            </motion.div>
            <motion.div
              animate={{ opacity: 1 }}
              className="mx-auto mb-4 flex max-w-6xl flex-col rounded-xl bg-white/80 py-4 pl-8 pr-4"
              initial={{ opacity: 0 }}
            >
              <h4 className="mb-4 text-xl">Statistics</h4>

              <div className="flex flex-wrap gap-4">
                <div className="flex flex-col gap-1">
                  <Tooltip content="Number of inference requests sent to this node" side="top">
                    <div className={twMerge(sectionTitleStyles, 'flex items-center gap-1')}>
                      <span>Requests Count</span>
                      <Icon className="size-4" name="infoCircle" />
                    </div>
                  </Tooltip>
                  <div className="flex items-center gap-2 text-lg">
                    {stat?.requestsCount ?? '--'} {isFetchingStat && <Spinner className="size-4" />}
                  </div>
                </div>
                <div className="flex flex-col gap-1">
                  <Tooltip
                    content="Number of inference requests successfully completed by this node"
                    side="top"
                  >
                    <div className={twMerge(sectionTitleStyles, 'flex items-center gap-1')}>
                      <span>Response Count</span>
                      <Icon className="size-4" name="infoCircle" />
                    </div>
                  </Tooltip>
                  <div className="flex items-center gap-2 text-lg">
                    {stat?.responseCount ?? '--'} {isFetchingStat && <Spinner className="size-4" />}
                  </div>
                </div>
                <div className="flex flex-col gap-1">
                  <div className={sectionTitleStyles}>Uptime</div>
                  <div className="flex items-center gap-2 text-lg">
                    {stat?.uptime ?? '--'} mins {isFetchingStat && <Spinner className="size-4" />}
                  </div>
                </div>
                <div className="flex flex-col gap-1">
                  <div className={sectionTitleStyles}>Latest heartbeat</div>
                  <div className="flex items-center gap-2 text-lg">
                    {lastHeartbeat?.created_at
                      ? DateTime.fromSeconds(lastHeartbeat?.created_at).toLocaleString({
                          dateStyle: 'short',
                          timeStyle: 'medium',
                        })
                      : '--'}

                    {isFetchingLastHeartbeat && <Spinner className="size-4" />}
                  </div>
                </div>
              </div>
            </motion.div>
          </>
        )}
      </AnimatePresence>
    </AnimateRoute>
  );
};
