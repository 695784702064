type Props = {
  status: number;
};
export const NodeStatus = ({ status }: Props) => {
  if (status === 1) {
    return (
      <div className="flex items-center gap-2">
        <div className="size-3 rounded-full bg-green-800"></div> Up
      </div>
    );
  }
  if (status === 2) {
    return (
      <div className="flex items-center gap-2">
        <div className="size-3 rounded-full bg-red-800"></div> Down
      </div>
    );
  }
  return (
    <div className="flex items-center gap-2">
      <div className="size-3 rounded-full bg-corduroy-400"></div> Unknown
    </div>
  );
};
