import type { ReactNode } from 'react';

import { motion } from 'framer-motion';
import { twMerge } from 'tailwind-merge';

type Props = {
  children: ReactNode;
  className?: string;
};

export const AnimateRoute = ({ children, className }: Props) => {
  return (
    <motion.div
      animate={{ opacity: 1 }}
      className={twMerge('px-4 py-6 md:px-8', className)}
      initial={{ opacity: 0 }}
      transition={{ duration: 0.2 }}
    >
      {children}
    </motion.div>
  );
};
